import CheckBox from "@/components/Common/Inputs/CheckBox/CheckBox";
import MarketplaceAd from "@/components/Components/MarketplaceAd/MarketplaceAd";


export default {
    name: 'MarketplaceGrid',
    props: {
        title: {
            type: String,
            default: 'medium'
        },
        products: {
            type: Array,
            default: null
        },
    },
    components: {
        CheckBox,
        MarketplaceAd

    },
    mounted() {
    },
    data() {
        return {
            filterBoxActive: false,
        }
    },
    methods: {
        toggleFilterBox() {
            this.filterBoxActive = !this.filterBoxActive
        }
    }
}

