
<script src="./index.js"></script>
<style src="./style.scss" lang="scss" scoped/>
<template>
  <div v-bind:class="{
    'input-field': true,
    'progress-slider-grey': false
  }">
    <div class="grid-container" v-if="products">

      <div class="grid-products d-flex">
        <div class="marketplace-grid-item" v-for="product in products">
          <div class="inner-grid-item">
            <MarketplaceAd
                :id="product.id"
                :labelnew="true /* todo:! */"
                :labeldiscount="true"
                :title="product.title"
                :category="product.categories.length > 0 ? $t(product.categories[0].title) : ''"
                :regularamount="product.price"
                :discountedamount="product.promoPrice"
                :image="product.mainImage !== null ? product.mainImage.src : ''"
            />
          </div>
        </div>
      </div>
    </div>

  </div>
</template>

