import CheckBox from "@/components/Common/Inputs/CheckBox/CheckBox";
import MarketplaceAd from "@/components/Components/MarketplaceAd/MarketplaceAd";


export default {
    name: 'MarketplaceGridTitle',
    props: {
        title: {
            type: String,
            default: 'medium'
        },
        filtertype: {
            type: String,
            default: ''
        },
        option1: {
            type: String,
            default: ''
        },
        option2: {
            type: String,
            default: ''
        },
        option3: {
            type: String,
            default: ''
        },
        closedfilter: {
            type: String,
            default: ''
        }
    },
    components: {
        CheckBox,
        MarketplaceAd

    },
    mounted() {
    },
    data() {
        return {
            filterBoxActive: false
        }
    },
    methods: {
        toggleFilterBox() {
            this.filterBoxActive = !this.filterBoxActive
        }
    }
}

