<script src="./index.js"></script>
<style src="./style.scss" lang="scss" scoped/>
<template>
  <div v-bind:class="{
    'input-field': true,
    'progress-slider-grey': theme === 'grey',

  }">
    <div class="grid-title">{{ title }}</div>

  </div>
</template>

