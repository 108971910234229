<script src="./index.js"></script>
<style src="./style.scss" lang="scss" scoped/>
<template>
  <div id="main-container" class="container">
    <div class="row">
      <div class="col-md-2 filter-container d-none d-md-block">
        <div class="filter-title">Филтриране</div>
        <div>
          <div class="row">
            <div class="col-md-12 mt-3">
              <InputField @input="updateFilters" v-model="priceFrom" :content="priceFrom" :inputtype="'text'"
                          :size="'small'"/>
            </div>
            <div class="col-md-12 mt-2">
              <InputField @input="updateFilters" v-model="priceTo" :content="priceTo" :inputtype="'text'"
                          :size="'small'"/>
            </div>
          </div>
        </div>
        <div v-for="filter in filters">
          <MarketplaceFilter
              v-model="appliedFilters[filter.title]"
              :title="filter.title"
              :filtertype="'checkbox'"
              :options="filter.options"
          />
        </div>
      </div>
      <div class="col-md-10 products-container"
           v-bind:class="{ 'open-filter-container-mobile': filterContainerActive }">
        <div class="d-flex">
          <div class="me-auto">
            <MarketplaceGridTitle :title="$t(category.title)" v-if="category"/>
          </div>
          <div class="d-none d-md-block">
            <MarketplaceSortFilter @sort="sortResults"/>
          </div>
        </div>
        <div class="row d-md-none">
          <div class="col-6" v-on:click="toggleFilterContainer">
            <AboutButton :text="'Филтрирай'"/>
          </div>
          <div class="col-6">
            <MarketplaceSortFilter/>
          </div>
          <div class=" filter-container-mobile d-md-none">
            <div class=" filter-title align-items-center" v-on:click="toggleFilterContainer">
              <div>
                <MarketplaceGridTitle :title="$t(category.title)" v-if="category"/>
              </div>
              <div class="d-flex">
                <div class=" me-auto">{{ $t('filter-title') }}</div>
                <svg width="21" height="21" viewBox="0 0 21 21" fill="none" xmlns="http://www.w3.org/2000/svg">
                  <path d="M0.857422 0.856445L19.9998 19.9988" stroke="black" stroke-width="1.00879"
                        stroke-linecap="round"/>
                  <path d="M20 0.856445L0.857622 19.9988" stroke="black" stroke-width="1.00879" stroke-linecap="round"/>
                </svg>
              </div>
            </div>

            <div class="marketplace-filter" v-for="filter in filters">
              <MarketplaceFilter
                  :title="filter.title"
                  :filtertype="filter.filterType"
                  :option1="filter.option1"
                  :option2="filter.option2"
                  :option3="filter.option3"
              />
            </div>

            <div class="filter-buttons-mobile-wrapper d-flex">

              <div class="col-6 me-2">
                <Button :text="$t('clear-filters')" :size="'medium'" :theme="'solid-grey'"/>
              </div>
              <div class="col-6" v-on:click="toggleFilterContainer">
                <Button :text="$t('apply-filters')" :size="'medium'" :theme="'solid-orange'"/>
              </div>
            </div>
          </div>
        </div>
        <div v-if="products">
          <MarketplaceGrid :products="products"/>
        </div>
        <div class="pagination mt-5 mb-5" v-if="products && products.length > 0">
          <Pagination
              :size="'medium'"
              :pages="pages"
              :route="'MarketplaceCategoryPage'"
              @pageChanged="handlePageChange"
          />
        </div>
        <div class="alert alert-danger" role="alert" v-else-if="products !== null">
          {{ $t('alert-no-products-found-in-category') }}
        </div>
        <PreLoader v-if="products === null"/>

      </div>
    </div>
  </div>
  <div>
    <Footer/>
  </div>
</template>
