<script src="./index.js"></script>
<style src="./style.scss" lang="scss" scoped/>
<template>
  <div v-bind:class="{
    'input-field': true,
    'progress-slider-grey': false
  }">
    <div class="filter-wrapper" v-bind:class="{ 'open-filter-box': filterBoxActive }">

      <div class="d-flex title-container"  v-on:click="toggleFilterBox">
        <div class="filter-title me-auto">{{ title }}</div>
        <svg width="9" height="14" viewBox="0 0 9 14" fill="none" xmlns="http://www.w3.org/2000/svg">
          <path d="M4.19843 12.8516C4.42269 13.0759 4.78629 13.0759 5.01055 12.8516L8.6651 9.19704C8.88937 8.97278 8.88937 8.60918 8.6651 8.38492C8.44084 8.16066 8.07724 8.16066 7.85298 8.38492L4.60449 11.6334L1.356 8.38492C1.13174 8.16066 0.768143 8.16066 0.543881 8.38492C0.31962 8.60918 0.31962 8.97278 0.543881 9.19704L4.19843 12.8516ZM4.03024 0.960388L4.03024 12.4455L5.17875 12.4455L5.17875 0.960388L4.03024 0.960388Z" fill="#1C1E2D"/>
        </svg>
      </div>

      <div class="filter-slider" v-if="filtertype === 'slider'">
        <div class="row">
          <div class="col-md-12 mt-3">
            <InputField v-model="priceFrom" :content="priceFrom" :inputtype="'text'" :size="'small'"/>
          </div>
          <div class="col-md-12 mt-2">
            <InputField v-model="priceTo" :content="priceTo" :inputtype="'text'" :size="'small'"/>
          </div>
        </div>
      </div>

      <div class="filter-checkbox" v-if="filtertype === 'checkbox' || filtertype === 'radio'">
        <div class="d-flex filter-option" v-for="option in options">
          <div v-if="filtertype === 'checkbox'">
            <CheckBox v-model="value[option]" :value="option"/>
          </div>
          <div v-if="filtertype === 'radio'">
            <RadioButton/>
          </div>
          <div class="filter-option-text" >{{ option }}</div>
        </div>

      </div>
    </div>
  </div>
</template>

